<template>
  <div class="pre-set">
<!--    <div class="row">-->
<!--      <div class="col s12">-->
<!--        <div class="card-panel white z-depth-2 center-align">-->
<!--          <span class="blue-text">Загрузка подготовленных полей или игры-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row flex flex-wrap" v-for="(p,pIndex) in hub.players" :key="pIndex">
      <div class="col s12 m6 l6">
        <div class="input-field">
          <input id="player_field_code" type="text" v-model="code[pIndex]">
          <label for="player_field_code" class="active">Вставьте код для загрузки поля игрока {{pIndex+1}}</label>
        </div>
      </div>
      <div class="col s12 m6 l6">
        <div class="input-field">
          <a class="waves-effect waves-light btn blue lighten-2" @click="load(pIndex+1)">Загрузить поле игрока {{pIndex+1}}</a>
        </div>
      </div>
    </div>
    <div class="row flex flex-wrap">
      <div class="col s12 m6 l6">
        <div class="input-field">
          <input id="game_code" type="text" v-model="game">
          <label for="game_code" class="active">Вставьте код игры</label>
        </div>
      </div>
      <div class="col s12 m6 l6">
        <div class="input-field">
          <a class="waves-effect waves-light btn blue lighten-2" @click="loadGame">Загрузить игру</a>
        </div>
      </div>
    </div></div>
</template>

<script>
import eventHub from "@/eventHub";
export default {
  name: "gamePreset",
  data(){
    return {
      hub: eventHub,
      dim: 10,
      code: ['',''],
      game:'',
    };
  },
  methods:{
    load(pN){
      let cell=null;
      let parts=this.code[pN-1].split('-');
      if (parts && parts.length===4){
//        let c=(parseInt(parts[0],26).toString(2).padStart(50,'0')+parseInt(parts[1],26).toString(2).padStart(50,'0')).split("");
        let c=(parseInt(parts[0],26).toString(3).padStart(25,'0')+parseInt(parts[1],26).toString(3).padStart(25,'0')+parseInt(parts[2],26).toString(3).padStart(25,'0')+parseInt(parts[3],26).toString(3).padStart(25,'0')).split("");
        console.log(c);
        for (let y = 0; y < this.dim; y++) {
          for (let x = 0; x < this.dim; x++) {
            cell=c.shift();
            if (cell === '1')
              this.hub.$set(this.hub.players[pN - 1].field[y],x,'ship');
            else
            if (cell === '2')
              this.hub.$set(this.hub.players[pN - 1].field[y],x,'bomb');
            else
              this.hub.$set(this.hub.players[pN - 1].field[y],x,null);
          }
        }
        this.saveShips(pN);
      }
    },
    test(x, y, skip, pN) {
      if (x < 0 || y < 0 || x >= this.dim || y >= this.dim) return 0;
      if (!skip.has(x + this.dim * y)) {
        if (this.hub.players[pN - 1].field[y][x])
          return this.hub.players[pN - 1].field[y][x];
      }
      return 0;
    },
    saveShips(pN) {
      this.hub.players[pN - 1].ships = Array.from(Array(this.hub.shipMaxSize), () => new Array());
      let skip = new Set();
      let queue = new Array();
      for (let y = 0; y < this.dim; y++) {
        for (let x = 0; x < this.dim; x++) {
          if (this.hub.players[pN - 1].field[y][x] !== 'ship') {
            skip.add(x + this.dim * y);
          }
        }
      }
      for (let y = 0; y < this.dim; y++) {
        for (let x = 0; x < this.dim; x++) {
          if (this.hub.players[pN - 1].field[y][x] === 'ship' && !skip.has(x + this.dim * y)) {
            queue.push({x, y});
            skip.add(x + this.dim * y);
          } else continue;
          let ship = [];
          while (queue.length) {
            let cx = queue[0].x;
            let cy = queue[0].y;
            ship.push({x: cx, y: cy})
            let shi = [[0, 1], [0, -1], [1, 0], [-1, 0]];
            for (let s = 0; s < 4; s++)
              if (this.test(cx + shi[s][0], cy + shi[s][1], skip, pN) === 'ship') {
                queue.push({x: cx + shi[s][0], y: cy + shi[s][1]});
                skip.add((cx + shi[s][0]) + this.dim * (cy + shi[s][1]))
              }
            queue.shift();
          }
          if (ship.length > 0 && ship.length <= this.hub.shipMaxSize)
            this.hub.players[pN - 1].ships[ship.length - 1].push(ship);
        }
      }
      for (let ss=0;ss<this.hub.shipMaxSize;ss++)
        if (this.hub.players[pN - 1].ships[ss].length>0) console.log((ss+1)+':'+this.hub.players[pN - 1].ships[ss].length);
    },
    saveBombs(pN) {
      let bc=0;
      for (let y = 0; y < this.dim; y++) {
        for (let x = 0; x < this.dim; x++) {
          if (this.hub.players[pN - 1].field[y][x] === 'bomb') {
            bc++;
          }
        }
      }
      if (bc>14){
        alert('Слишком много мин, поведение будет непредсказуемым!')
        bc=14;
      }
      let r=[];
      for (let i=0;i<bc;i++) r.push(Math.random());
      let s=r.slice();
      s.sort();
      this.hub.players[pN - 1].bombs=[];
      for (let i=0;i<bc;i++) this.hub.players[pN - 1].bombs.push(r.indexOf(s.shift()));
    },
    loadGame(){
      let cell=null;
      let fparts=this.game.split(':');
      if (fparts && fparts.length===2)
      for (let pN=1;pN<=2;pN++)
      {
        let parts=fparts[pN-1].split('-');
        if (parts && parts.length===4){
//          let c=(parseInt(parts[0],26).toString(3).padStart(50,'0')+parseInt(parts[1],26).toString(3).padStart(50,'0')).split("");
          let c=(parseInt(parts[0],26).toString(3).padStart(25,'0')+parseInt(parts[1],26).toString(3).padStart(25,'0')+parseInt(parts[2],26).toString(3).padStart(25,'0')+parseInt(parts[3],26).toString(3).padStart(25,'0')).split("");
          for (let y = 0; y < this.dim; y++) {
            for (let x = 0; x < this.dim; x++) {
              cell=c.shift();
              if (cell === '1')
                this.hub.$set(this.hub.players[pN - 1].field[y],x,'ship');
              else
              if (cell === '2')
                this.hub.$set(this.hub.players[pN - 1].field[y],x,'bomb');
              else
                this.hub.$set(this.hub.players[pN - 1].field[y],x,null);
            }
          }
          this.saveShips(pN);
          this.saveBombs(pN);
        }
      }
    },
  },
  mounted() {
    // this.hub.$on('updateGameCode',this.updateGameCode);
    if (this.hub.gameCode) this.game=this.hub.gameCode;
  },
}
</script>

<style scoped lang="scss">
.btn{
  width: 100%;
  margin-bottom: 5px;
}
</style>